/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 20번째 로그에서는 Notion 업데이트, Firefox Send 릴리스, AWS ECS 스케줄 테스크에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.amazon.com/Blue-Yeti-Premium-Recording-Streaming/dp/B07DTTGZ7M/ref=sr_1_1_sspa?keywords=yeti+nano&qid=1552520385&s=gateway&sr=8-1-spons&psc=1"
  }, "Amazon.com: Blue Yeti Nano Premium USB Mic")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.notion.so/What-s-New-157765353f2c4705bd45474e5ba8b46c"
  }, "What's New? - notion.so")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://send.firefox.com/"
  }, "Firefox Send")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.clien.net/service/board/park/13246621"
  }, "애플도 PPL을 하는군요 : 클리앙")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/AirDrop"
  }, "AirDrop - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Mozilla"
  }, "Mozilla - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Mozilla_Corporation"
  }, "Mozilla Corporation - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Mozilla/Firefox/Multiple_profiles"
  }, "Multiple Firefox profiles - Mozilla | MDN")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/about-aws/whats-new/2019/03/amazon-ecs-introduces-enhanced-container-dependency-management/"
  }, "Amazon ECS, 향상된 컨테이너 종속성 관리 출시")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.docker.com/engine/reference/run/#healthcheck"
  }, "Docker run reference#healthcheck | Docker Documentation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/docker/compose/issues/374"
  }, "Is there a way to delay container startup to support dependant services with a longer startup time · Issue #374 · docker/compose")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/vishnubob/wait-for-it"
  }, "vishnubob/wait-for-it: Pure bash script to test and wait on the availability")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://firebase.google.com/pricing/"
  }, "Firebase Plan")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.samsungpop.com/common.do?cmd=down&saveKey=research.pdf&fileName=2010/2019012916305070K_02_20.pdf&contentType=application/pdf"
  }, "삼성증권 레포트 - 클라우드 전쟁")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://platum.kr/archives/108407"
  }, "베스핀글로벌, 870억 원 규모 시리즈B 투자 유치 - 'Startup's Story Platform’")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cloud.hosting.kr/press_190304_megazonecloud/"
  }, "메가존클라우드, 국내 대표 투자기관 4곳 480억원 투자 유치")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.zdnet.co.kr/view/?no=20180920172135"
  }, "기자수첩 - 공공 클라우드의 민간 개방, 새 문 열린다 - ZDNet korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mois.go.kr/frt/bbs/type001/commonSelectBoardArticle.do?bbsId=BBSMSTR_000000000015&nttId=68162"
  }, "행정·공공기관 민간 클라우드 이용 가이드라인 | 행정안전부")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/ko_kr/systems-manager/latest/userguide/systems-manager-paramstore.html"
  }, "AWS Systems Manager Parameter Store - AWS Systems Manager")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/systems-manager/"
  }, "AWS Systems Manager – AWS 리소스에 대한 운영 통찰력을 확보하고 조치를 수행")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/kms/latest/developerguide/concepts.html#enveloping"
  }, "AWS Key Management Service Concepts#Envelope Encryption")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/about-aws/whats-new/2018/11/aws-launches-secrets-support-for-amazon-elastic-container-servic/"
  }, "AWS Launches Secrets Support for Amazon Elastic Container Service")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://forums.aws.amazon.com/thread.jspa?messageID=835388&tstart=0"
  }, "AWS Developer Forums: Parameter Store pagination limits are ...")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/segmentio/chamber"
  }, "segmentio/chamber: CLI for managing secrets")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/fugue/credstash"
  }, "fugue/credstash: A little utility for managing credentials")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/christippett/ssm-parameter-store"
  }, "christippett/ssm-parameter-store: Simple Python wrapper for getting values from AWS Systems Manager Parameter Store")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/AmazonECS/latest/developerguide/scheduling_tasks.html"
  }, "Scheduling Amazon ECS Tasks - Amazon Elastic Container Service")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/AmazonECS/latest/developerguide/CWE_IAM_role.html"
  }, "CloudWatch Events IAM Role - Amazon Elastic Container Service")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.netlify.com/"
  }, "Netlify: All-in-one platform for automating modern web projects.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://apex.run/"
  }, "Apex – Serverless Infrastructure")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/apex/apex/issues/894"
  }, "Monetize with Pro version · Issue #894 · apex/apex")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/premiumsupport/knowledge-center/internet-access-lambda-function/"
  }, "Grant Internet Access to a VPC Lambda Function")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://serverless.com/"
  }, "Serverless - The Serverless Application")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
